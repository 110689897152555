import React, { Suspense, lazy } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import routes from "core/constants/routes";
import PrivateRoute from "core/components/PrivateRoute";
import Navbar from "core/components/Navbar";
import history from "core/utils/browserHistory";

const OffersList = lazy(() => import("modules/OffersList"));
const OfferDetails = lazy(() => import("modules/OfferDetails"));
const SectionsList = lazy(() => import("modules/SectionsList"));
const LessonsList = lazy(() => import("modules/LessonsList"));
const Notifications = lazy(() => import("modules/Notifications"));
const Topics = lazy(() => import("modules/Topics"));
const Auth = lazy(() => import("modules/Auth"));
const Certificate = lazy(() => import("modules/Certificate/CheckCertificate"));
const MyCertificates = lazy(() => import("modules/Certificate/MyCertificates"));
const Profile = lazy(() => import("modules/Profile"));

const Routes = () => {
  return (
    <Router history={history}>
      <Navbar />
      <Suspense fallback="">
        <Switch>
          <Redirect from="/" to="/offers" exact />
          <PrivateRoute path={routes.offers} exact>
            <OffersList />
          </PrivateRoute>
          <PrivateRoute path={routes.offerDetails} exact>
            <OfferDetails />
          </PrivateRoute>
          <PrivateRoute path={routes.resourceSectionsList} exact>
            <SectionsList />
          </PrivateRoute>
          <PrivateRoute path={routes.sectionItemsList} exact>
            <LessonsList />
          </PrivateRoute>
          <PrivateRoute path={routes.notifications} exact>
            <Notifications />
          </PrivateRoute>
          <PrivateRoute path={routes.topics}>
            <Topics />
          </PrivateRoute>
          <PrivateRoute path={routes.myCertificates}>
            <MyCertificates />
          </PrivateRoute>
          <PrivateRoute path={routes.profile}>
            <Profile />
          </PrivateRoute>
          <Route path={routes.auth} component={Auth} />
          <Route path={routes.certificate} component={Certificate} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
