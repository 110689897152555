import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAllowedByRole, isAuthenticated, Roles } from 'core/utils/auth';
import routes from 'core/constants/routes';

type Props = {
  children: React.ReactNode;
  path: string;
  allowedRoles?: Roles[];
  exact?: boolean;
};

const PrivateRoute = ({ children, allowedRoles, exact, ...rest }: Props) => {
  const checkIsAllowedByRole = allowedRoles?.length ? isAllowedByRole(allowedRoles) : true;
  const isAccessGranted = isAuthenticated() && checkIsAllowedByRole;

  return (
    <Route
      {...rest}
      exact={exact}
      render={({ location }) =>
        isAccessGranted ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: routes.login,
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

export default PrivateRoute;

