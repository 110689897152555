import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import { authenticatedRequest } from "core/utils/request";
import { NotificationResponse } from "modules/Notifications/types";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  getAccessTokenDecoded,
  isAuthenticated,
  logout,
} from "core/utils/auth";
import "./styles.scss";

const NavbarComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [notificationsLength, setNotificationsLength] = useState<number>();

  useEffect(() => {
    if (userName && isAuthenticated()) {
      authenticatedRequest(
        `/notifications?unreadOnly=true`
      ).then((response: NotificationResponse) =>
        setNotificationsLength(response.totalElements)
      );
    }
  }, [userName]);

  useEffect(() => {
    const { sub } = getAccessTokenDecoded();
    setUserName(sub);
  }, [location]);

  const renderUserActions = () => (
    <>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      {/* Responsive menu */}
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/offers" className="d-lg-none nav-link">
            Meus cursos
          </Link>
          <Link to="/certificates" className="d-lg-none nav-link">
            Certificados
          </Link>
          <Link to="/profile" className="d-lg-none nav-link">
            Meu cadastro
          </Link>
          <Link to="/notifications" className="d-lg-none nav-link">
            Notificações
            <span className="ml-2 badge badge-pill badge-danger">
              {notificationsLength}
            </span>
          </Link>
          <Nav.Link className="d-lg-none" onClick={logout}>
            Sair
          </Nav.Link>
        </Nav>
        <Form inline className="d-none d-lg-block">
          {isAuthenticated() && (
            <ul className="navbar-nav align-items-center">
              <li
                className="nav-item text-light font-weight-bold cursor-pointer"
                onClick={() => history.push("/offers")}
              >
                Meus cursos
              </li>
              <li
                className="nav-item text-light font-weight-bold cursor-pointer mx-5"
                onClick={() => history.push("/certificates")}
              >
                Certificados
              </li>
              <li
                className="nav-item text-light font-weight-bold cursor-pointer"
                onClick={() => history.push("/notifications")}
              >
                <img
                  className="notification-icon"
                  src={require(`../../assets/images/bell.svg`)}
                  alt="Notificações"
                />
                <span className="ml-2 badge badge-pill badge-danger">
                  {notificationsLength}
                </span>
              </li>
              <li className="ml-4 nav-item text-light font-weight-bold">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {userName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="bg-secondary text-light">
                    <Dropdown.Item
                      className="bg-secondary text-light"
                      onClick={() => history.push("/profile")}
                    >
                      Meu cadastro
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="bg-secondary text-light"
                      onClick={logout}
                    >
                      Sair
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          )}
        </Form>
      </Navbar.Collapse>
    </>
  );

  return (
    <div className="bg-dark main-nav">
      <div className="container">
        <Navbar bg="dark" variant="dark" expand="lg">
          <Link to="/" className="navbar-brand">
            <img
              src={require(`../../assets/images/complete_logo.svg`)}
              alt="DevSuperior"
              className="logo-header"
            />
          </Link>
          <span className="d-none d-sm-block">Portal do aluno</span>
          {userName && renderUserActions()}
        </Navbar>
      </div>
    </div>
  );
};

export default NavbarComponent;
