const home = "/";
const auth = "/auth";
const login = `${auth}/login`;
const recover = `${auth}/recover`;
const reset = `${auth}/reset`;
const offers = "/offers";
const offerDetails = `${offers}/:offerId`;
const resourceSectionsList = `${offerDetails}/resource/:resourceId`;
const sectionItemsList = `${resourceSectionsList}/sections/:sectionId`;
const notifications = "/notifications";
const topics = `${offerDetails}/topics`;
const newTopic = `${offerDetails}/topics/new`;
const certificate = "/certificados";
const myCertificates = "/certificates";
const profile = "/profile";

export default {
  home,
  auth,
  login,
  recover,
  reset,
  offers,
  offerDetails,
  resourceSectionsList,
  sectionItemsList,
  notifications,
  topics,
  newTopic,
  certificate,
  myCertificates,
  profile,
} as const;
