import axios, { Method } from "axios";
import routes from "core/constants/routes";
import history from "../utils/browserHistory";
import { getAccessToken } from "./auth";

export const BASE_URL =
  process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8080";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ([403, 401].includes(error.response.status)) {
      history.replace(routes.login);
    }
    return Promise.reject(error);
  }
);

export const buildHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${getAccessToken()}`,
});

export const baseRequest = async (
  resource: string,
  method: Method = "GET",
  data?: any,
  params?: any
) => {
  return axios({
    method,
    url: `${BASE_URL}${resource}`,
    data,
    params,
    headers: buildHeaders(),
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};

export const authenticatedRequest = (
  resource: string,
  method: Method = "GET",
  data?: any,
  params?: any
) => {
  return axios({
    method,
    url: `${BASE_URL}${resource}`,
    data,
    params,
    headers: buildHeaders(),
  })
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err.response);
    });
};
