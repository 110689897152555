import routes from 'core/constants/routes';
import JwtDecode from 'jwt-decode';
import history from "../utils/browserHistory";

export type Roles = 'ADMIN' | 'OPERATOR';

type Token = {
  sub: string;
  roles: Roles[];
  iat: number;
  exp: number;
};

export const getAccessToken = () => localStorage.getItem('token') || '';

export const getAccessTokenDecoded = (): Token => {
  try {
    return JwtDecode(getAccessToken());
  } catch (e) {
    return {} as Token;
  }
};

export const isTokenValid = () => {
  const { exp } = getAccessTokenDecoded();

  if (Date.now() >= exp * 1000) {
    return false;
  }

  return true;
}

export const isAuthenticated = () => getAccessToken() && isTokenValid();

export const isAllowedByRole = (routeRoles: Roles[]) => {
  const { roles } = getAccessTokenDecoded();

  return routeRoles.some(route => {
    return roles.includes(route);
  });
}

export const logout = () => {
  localStorage.clear();
  history.replace(routes.login);
}